<template lang="pug">
div
  DetailFull(v-if="loaded")
    template(slot="title-prefix")
      | Schedule Item: 
    template(slot="title")
      | {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} - 
      span.font-weight-bold {{item.location.template.name}}
    template(slot="controls")
      Controls
    template(slot="form")
      v-container
        PIDetail
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailFull from '@/components/base/detail-full-page'
import { detail as PIDetail } from '@/modules/production-instances'
import Controls from './controls'
import Actions from './actions'
// import DetailForm from '../form'

export default {
  components: {
    DetailFull,
    PIDetail,
    Controls,
    Actions
  },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      loaded: 'loaded'
    }),
    ...mapFields('dashboards', {
      actions: 'actions'
    })
  },
  async created () {
    await this.$store.dispatch('productionInstances/_READ_FROM_DB', this.$route.params.id)
  }
}
</script>
